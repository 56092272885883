body {
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
}

body.new-ui {
  background-color: #f6f6f6;
  font-family: 'Inter', sans-serif;
}

.p-panel .p-panel-content,
p-toggleable-content {
  border: none;
  background-color: rgba(180, 208, 182, 0.3);
}

.p-enabled-rdw .rdw-option-wrapper {
  background-color: rgba(180, 208, 182, 0.3);
}

.p-disabled-rdw .rdw-option-wrapper {
  background-color: #f3f4fb;
  pointer-events: none;
}

.p-button {
  background-color: #e75113;
  border-color: #e75113;
}

.p-button:enabled:hover {
  background-color: #fa8656;
  border-color: #fa8656;
}

.p-button.p-button-outlined {
  color: #e75113;
}

.p-button.p-button-text {
  background-color: transparent;
  color: #e75113;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(0, 123, 255, 0.04);
  color: #fa8656;
}

.custom-dialog .p-button.p-button-text {
  border-color: #e75113;
}

.custom-dialog .p-button.p-button-text:enabled:hover {
  border-color: #fa8656;
}

.custom-dialog .p-button {
  height: 3rem;
}

.p-dialog .p-dialog-footer button {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.p-dialog-footer {
  display: flex;
  justify-content: center;
}

.p-dialog-footer .p-button {
  min-width: 170px;
}

.p-dataview .p-dataview-content {
  background-color: transparent;
}

.p-dataview .p-dataview-header {
  background-color: transparent;
  border: none;
}

.paginator-right {
  background-color: transparent;
  justify-content: end;
  border: none;
}

.p-dialog-header .p-dialog-title {
  display: flex;
  justify-content: center;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  background-color: transparent;
}

.p-dialog-content .p-listbox {
  border: none;
}

.p-dialog-content .p-listbox .p-listbox-header {
  border: none;
  background: none;
  width: fit-content;
}

.p-dialog-content .p-listbox .p-listbox-list .p-listbox-item {
  padding: 0 1rem;
}

/* scrollbar customization */
.p-listbox-list-wrapper,
.p-dialog .p-dialog-content,
.p-with-custom-scroll,
.rdw-editor-main,
.p-datatable-wrapper {
  --sb-track-color: #ffffff;
  --sb-thumb-color: #d9dadb;
  scrollbar-width: thin;
  --sb-size: 10px;
  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
}

.p-listbox-list-wrapper::-webkit-scrollbar,
.p-dialog .p-dialog-content::-webkit-scrollbar,
.p-with-custom-scroll::-webkit-scrollbar {
  width: var(--sb-size);
}

.p-listbox-list-wrapper::-webkit-scrollbar-track,
.p-dialog .p-dialog-content::-webkit-scrollbar-track,
.p-datatable-wrapper::-webkit-scrollbar-track,
.p-with-custom-scroll::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 10px;
}

.p-listbox-list-wrapper::-webkit-scrollbar-thumb,
.p-dialog .p-dialog-content::-webkit-scrollbar-thumb,
.p-datatable-wrapper::-webkit-scrollbar-thumb,
.p-with-custom-scroll::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 10px;
}

.p-inputtext {
  border: 1px solid grey;
  border-radius: 0;
  /* padding: 1rem; */
}

.p-button:focus,
.p-dialog .p-dialog-header .p-dialog-header-icon:focus,
.p-checkbox.p-checkbox-focused .p-checkbox-box.p-focus,
.p-panel .p-panel-header .p-panel-header-icon:focus {
  box-shadow: none;
}

.p-component.p-disabled {
  background-color: #eef1f4;
  opacity: 1;
  border: #eef1f4;
}

.Toastify__toast-icon {
  align-self: flex-start;
}
.p-invalid.rdw-editor-main {
  border-color: #dc3545 !important;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0;
}

.p-dialog-content,
.p-dialog .p-dialog-content {
  padding: 0;
}

.p-dialog .p-dialog-footer {
  padding-bottom: 2rem;
}

.grid-text-vertical {
  writing-mode: vertical-lr;
}

.grid-text-vertical > * {
  transform: rotate(180deg);
}

@supports (-webkit-appearance: none) and (stroke-color: transparent) {
  .grid-text-vertical {
    vertical-align: bottom;
  }

  .grid-text-vertical div {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
  }

  .grid-text-vertical span {
    writing-mode: vertical-lr;
    white-space: nowrap;
  }
}

div.p-datepicker.p-component.p-ripple-disabled.p-connected-overlay-enter-done,
div.p-datepicker.p-component {
  min-width: 0 !important;
  width: auto !important;
}

/* PDF page breaks and orientation */
@page {
  size: A4 portrait;
  margin-top: 2.5cm;
  margin-bottom: 2.5cm;
  margin-left: 1cm;
  margin-right: 1cm;
  width: 100%;
  border: 0;
}

@page wide {
  size: A4 landscape;
}

@page cover {
  margin: 0;
}

@page final {
  margin: 0;
}

.final {
  display: none !important;
}

.landscape {
  page-break-before: always;
  size: A4 landscape !important;
  page: wide;
}

.cover {
  page: cover;
}

.final {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: rgba(180, 208, 182, 0.3);
  page-break-before: always;
  page: final;
}

/*temp approach for autogenerated grids*/
tr.hidelastcolumn > td:last-child {
  border-left: hidden !important;
  box-shadow: none !important;
  outline: none !important;
}

tr.hidelastcolumn > td:last-child * {
  display: none !important;
}
