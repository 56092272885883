.rct-node-collapsed {
  margin-bottom: 10px;
}
.rct-icon-expand-close::before {
  content: '+';
}

.rct-icon-expand-open::before {
  content: '-';
}

.rct-icon-parent-open::before {
  content: '-';
}

.rct-icon-parent-close::before {
  content: '+';
}

ol {
  content: 'hello';
  list-style-type: none;
}

.rct-node-expanded {
  list-style-type: none;
}
